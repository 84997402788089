<template>
    <div class="d-flex content-center min-vh-100">
        <BContainer>
            <BRow class="justify-content-center">
                <BCol md="8">
                    <BCard class="text-left" footer-tag="footer" footer-bg-variant="light" header-bg-variant="info" header-text-variant="light">
                        <div slot="header">
                            <strong>XCOST 회원정보 변경</strong>
                            <div class="card-header-actions">
                                <small class="text-white">XCOST 회원정보를 변경합니다.</small>
                            </div>
                        </div>


                        <BForm @submit="onSubmit">
                            <BFormGroup class="mt-3"
                                        label="회원 아이디"
                                        label-for="userIdInput"
                                        hidden>
                                <BInputGroup id="userIdInput">
                                    <BInputGroupPrepend is-text><BIconPersonFill/></BInputGroupPrepend>
                                    <BFormInput v-model="user.id" type="text" id="userId" required readonly/>
                                </BInputGroup>
                            </BFormGroup>

                            <BFormGroup class="mt-2" label="회원 성명" label-for="userNameInput">
                                <BInputGroup id="userNameInput">
                                    <BInputGroupPrepend is-text><BIconPersonFill/></BInputGroupPrepend>
                                    <BFormInput v-model="user.name" type="text" id="userName" required readonly/>
                                </BInputGroup>
                            </BFormGroup>

                            <BFormGroup class="mt-2" label="회원등급" label-for="userLevel">
                                <BInputGroup id="levelInput">
                                    <BInputGroupPrepend is-text><BIconLayersFill/></BInputGroupPrepend>
                                    <BFormSelect v-model="user.level" :options = "levelOpts" disabled/>
                                    <BInputGroupAppend  is-text>만료일</BInputGroupAppend>
                                    <BFormInput v-model="user.expireDate" type="text" id="userLevel" readonly/>
                                </BInputGroup>
                            </BFormGroup>

                            <BFormGroup class="mt-2" label="전화번호" label-for="hpInput">
                                <BInputGroup id="hpInput">
                                    <BInputGroupPrepend is-text><BIconAt/></BInputGroupPrepend>
                                    <BFormInput v-model="user.hp"
                                                :state="user.hp.length > 10"
                                                type="text"
                                                placeholder="휴대폰번호를 입력하세요"
                                                required
                                    />
                                </BInputGroup>
                            </BFormGroup>

                            <BFormGroup class="mt-2" label="이메일" label-for="emailInput">
                                <BInputGroup id="emailInput">
                                    <BInputGroupPrepend is-text><BIconAt/></BInputGroupPrepend>
                                    <BFormInput v-model="user.email"
                                                :state="validEmailState"
                                                type="email"
                                                placeholder="이메일 주소를 입력하세요"
                                                required
                                    />
                                </BInputGroup>
                            </BFormGroup>

                            <BFormGroup class="mt-2" label="회원 별명" label-for="userNickInput">
                                <BInputGroup id="userNickInput">
                                    <BInputGroupPrepend is-text><BIconPersonFill/></BInputGroupPrepend>
                                    <BFormInput v-model="user.nick" :state="user.nick.length > 1" type="text" id="userNick" required/>
                                </BInputGroup>
                            </BFormGroup>

                            <BFormGroup class="mt-2" label="비밀번호 재설정" description="비밀번호는 영문, 숫자, 특수문자 조합 8자 이상으로 구성됩니다">
                                <BFormInput v-model="user.newPwd"  :state="validPwdState" id="userPwd" type="password" placeholder="비밀번호 (영문+숫자+특수문자 조합으로 8자 이상)" :disabled="!isValidUser"/>
                                <BFormInput v-model="user.newPwdConfirm" :state="validPwdConfirm" class="mt-1"  type="password" placeholder="비밀번호를 다시 입력해주세요" :disabled="!isValidUser" aria-describedby="input-live-help input-live-feedback"/>
                                <b-form-invalid-feedback id="input-live-feedback">
                                    비밀번호를 확인해주세요
                                </b-form-invalid-feedback>
                            </BFormGroup>

                            <b-form-group label="이벤트 및 유용한 소식 수신 동의" v-slot="{ ariaDescribedby }">
                              <b-form-checkbox-group
                                id="checkbox-group-1"
                                v-model="receptionAgrSelected"
                                :aria-describedby="ariaDescribedby"
                                name="receptionAgr"
                              >
                                <b-form-checkbox value="mailling">Email 수신</b-form-checkbox>
                                <b-form-checkbox value="sms">SMS 수신</b-form-checkbox>
                              </b-form-checkbox-group>
                            </b-form-group>

                            <div class="text-right mt-3">
                                <BButton  variant="info" type="submit" :disabled="isSaveUser || !validEmailState || !validPwdConfirm ">회원정보 수정</BButton>
                            </div>

                        </BForm>


                        <div slot="footer">

                            <div class="small text-muted">
                                <strong><i class="fa fa-copyright"></i> <a href='http://qqkorea.net'>(주)하우코스트</a></strong>
                                <span class="hidden-xs en">All rights reserved.</span><br>
                                <span class="hidden-xs en">
                                    대표자 : 유현오 | E-Mail : <a href="mailto:mycost@naver.com">mycost@naver.com</a>) <br>
                                    <a href='http://xcost.me/bbs/qalist.php'>적산문의/프로그램/문의 1:1상담</a> | tel. 031-417-0466 | fax. 02-6442-0466 <br/>
                                    사무실 : 경기도 수원시 권선구 수성로 8 경기중소기업성장지원센터 303호 <br/>부설연구소 : 건설계약연구소/벤쳐기업인증/연구개발서비스업등록/소프트웨어개발업/ISO9001,ISO14001인증<br/>
                                    사업자번호 : 809-86-00250</span>
                            </div>
                        </div>

                    </BCard>


                </BCol>
            </BRow>
        </BContainer>


    </div>
</template>

<script>
    import {
        apiCall,
        cloneVar,
        alertError,
        alertWarn,
        alertSuccess,
        alertConfirm,
        warningAlertPageMove,
        checkBisNo,
        commify,
        toastSync,
        warpSite,
    } from '../../../common/utils';

    import DaumPostcode from 'vuejs-daum-postcode';
    import moment from "moment";

    export default {
        name: 'MemberInfoUpdate',
        props: ['memberToken'],
        data () {
            return {
              userName : '',
              userEmail: '',
              userId  : '',
              isSaveUser: false,
              newPwd: '',
              newPwdConfirm: '',
              failCount: 0,
              isValidUser: false,
              isPwdChecked: false,
              // pwdRegexp: /^[A-Za-z0-9+]{8,}$/, // 영어+숫자
              // pwdRegexp: /^(?=.*[0-9]+)[a-zA-Z][a-zA-Z0-9]{7,29}$/, // 영어+숫자(8자이상)
              pwdRegexp: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/, // 최소8자 영문,숫자,특수문자 조합
              emailRegexp: /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
              isXcUser: false,
              isWebUser: false,
              user: {
                  name: '',
                  nick: '',
                  no: '',
                  id: '',
                  email: '',
                  hp: '',
                  newPwd: '',
                  newPwdConfirm: '',
                  expireDate: '',
                  mailling: null,
                  sms: null,
              },
              levelOpts: [
                { value:  2, text: 'xcost.me 회원' },
                { value:  8, text: 'xcost.me 관리자' },
                { value:  10, text: 'xcost.me Admin' },
                { value: '20', text: '회원' },
                { value: 'P1', text: 'XCost 프리미엄-I 회원' },
                { value: 'P2', text: 'XCost 프리미엄-II 회원' },
                { value: 'P3', text: 'XCost 프리미엄-III 회원' },
                { value: 'P4', text: 'XCost 프리미엄-IV 회원' },
                { value: 'P5', text: 'XCost 프리미엄-V 회원' }
              ],
              receptionAgrSelected: [],
            }
        },


        watch: {
          'receptionAgrSelected': 'logTest',
        },


        async created(){
            let xcUser = null, webUser = null;
            console.log("-------- created: MemberInfoUpdate From ----- token : " + this.memberToken );
            try{
                const r = await apiCall( 'post', '/api/member/verify-token-for-update-info', {memberToken: this.memberToken} );
                console.log( r );
                xcUser = r.result.xcUser;
                webUser = r.result.webUser;
                if(r.code===200) {
                  this.isXcUser = true;
                  this.isWebUser = true;
                  this.isValidUser = true;
                  this.setUserInfo(xcUser);
                }else if( r.result.code===204){
                  this.isXcUser = true;
                  this.isValidUser = true;
                  this.setUserInfo(xcUser);
                }else if( r.result.code===240){
                  this.isWebUser = true;
                  this.isValidUser = true;
                  this.setWebUserInfo( webUser );
                }else {
                  this.isValidUser = false;
                  await warningAlertPageMove( this.$bvModal, '회원정보를 찾을수 없습니다', '정보 없음', '#/' );
                }

                if (this.user.mailling) this.receptionAgrSelected.push('mailling');
                if (this.user.sms) this.receptionAgrSelected.push('sms');

                // window.location.href = 'http://xcost.me';

            }catch(err){
                // await alertWarn( this.$bvModal, '링크가 만료되었거나 접근오류입니다', '정보 없음' );
                console.log(err);
                await warningAlertPageMove( this.$bvModal, 'ERROR: '+err.message, '에러', '#/' );
                return;
            }


            console.log("-------- created: MemberInfoUpdate From -----" );
            try{

            }catch(err){
                console.log(err);
                await warningAlertPageMove( this.$bvModal, 'ERROR: '+err.message, '에러', '#/' );
                return;
            }
        },


        beforeMount() {

        },
        mounted(){
            console.log("-------- mounted: IdPwdLost -----  " );

        },


        computed: {

            validPwdState(){
                // const p2 = this.newPwdConfirm;
                if( this.user.newPwd.length > 7 ){
                    if( this.pwdRegexp.test( this.user.newPwd ) ) return true;
                    else return false;
                }else{
                    return false;
                }
            },

            validEmailState(){
                if(!this.emailRegexp.test(this.user.email)) {
                    return false;
                } else {
                    return true;
                }
            },

            validPwdConfirm(){
                let ret = false;
                const p1 = this.user.newPwd;
                const p2 = this.user.newPwdConfirm;
                if( p2.length > 7 && p1 === p2 && this.pwdRegexp.test( this.user.newPwdConfirm ) ){
                    ret = true;
                  this.isPwdChecked = true;
                }else{
                  this.isPwdChecked = false;
                }
                return ret;
            },
        },


        methods: {
          logTest() {
            console.log("selected checkBox", this.receptionAgrSelected);
          },

          setUserInfo(xcUser){
            this.user.name = xcUser.name;
            this.user.no   = xcUser.userNo;
            this.user.id   = xcUser.userId;
            this.user.nick = xcUser.nick;
            this.user.email = xcUser.email;
            this.user.hp = xcUser.hpNo;
            this.user.level = xcUser.level;
            this.user.expireDate = (xcUser.level.indexOf('P') > -1)? moment(xcUser.lvEndDt).format("YYYY년 MM월 DD일"):'';
            this.user.mailling = xcUser.isMailing;
            this.user.sms = xcUser.isSms;

            return true;
          },

          setWebUserInfo(webUser){
            this.user.name = webUser.mb_name;
            this.user.no   = webUser.mb_no + '';
            this.user.id   = webUser.mb_id;
            this.user.nick = webUser.mb_nick;
            this.user.level = webUser.mb_level;
            this.user.hp = webUser.mb_hp;
            this.user.mailling = (webUser.mb_mailling === 1) ? true : false;
            this.user.sms = (webUser.mb_sms === 1) ? true : false;
          },

          async onSubmit(e){
            e.preventDefault();
            
            this.user.mailling = false;
            this.user.sms = false;
            this.receptionAgrSelected.forEach(selected => {
              if (selected === 'mailling') this.user.mailling = true;
              if (selected === 'sms') this.user.sms = true;
            });

            console.log( "----------- IdPwdLost ---> ");
            try{
              const param = {
                user : this.user,
                isXcUser : this.isXcUser,
                isWebUser : this.isWebUser,
                memberToken : this.memberToken
              };

              console.log("param =============> ", param);

              if( this.user.newPwdConfirm !== this.user.newPwd){
                await alertWarn( this.$bvModal, '재입력 비밀번호가 일치하지 않습니다.' );
                return;
              }

              const r = await apiCall('put', `/api/member/update-member-info`, param );
              console.log( r );
              let message = '';
              switch( r.code ){
                case 211: message = "XCost 및 웹 회원정보를 수정하였습니다"; break;
                case 201: message = "웹 회원정보를 수정하였습니다"; break;
                case 210: message = "XCost 회원정보를 수정하였습니다"; break;
                default: message = '회원정보 수정 완료'; break;
              }
              if(r.code < 400){
                await alertSuccess(this.$bvModal, message, "SUCCESS");
                // window.location.href = warpSite();
                window.close();
                return;
              }else{
                await warningAlertPageMove( this.$bvModal, '회원정보 수정 실패: '+ r.message, '정보 없음', '#/' );
              }

            }catch(err){
              console.log( err );
              await warningAlertPageMove( this.$bvModal, 'ERROR: ' + err.message, 'ERROR', '#/' );
            }
          },
        }
    }
</script>
